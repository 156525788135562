<template>
    <div v-if="!isRequestComplete" id="profileCard" class="text-center">
        <b-alert :show="showError" variant="danger" class="mt-4">{{ errorMessage }}</b-alert>
    <b-card bg-variant="light" style="max-width: 50%;" align="center" class="my-3 mx-auto text-center">
      <b-form-group @submit.prevent
        label-cols-lg="4"
        label="Create User Profile"
        label-size="lg"
        label-class="font-weight-bold pt-0"
        class="mb-0"
        >
        <b-form-group
            label-cols-sm="3"
            label="First Name"
            label-align-sm="right"
            label-for="nestedFirstName"
            >
            <b-form-input id="nestedFirstName" 
            v-model="form.firstName"
            type="text"
            required
            placeholder="Enter First Name"
            ></b-form-input>
        </b-form-group>
        <b-form-group
            label-cols-sm="3"
            label="Last Name"
            label-align-sm="right"
            label-for="nestedLastName"
            >
            <b-form-input id="nestedLastName"
            v-model="form.lastName"
            type="text"
            required
            placeholder="Enter Last Name"></b-form-input>
        </b-form-group>
        <b-form-group
            label-cols-sm="3"
            label="Username"
            label-align-sm="right"
            label-for="nestedUserName"
            >
            <b-form-input id="nestedUsername"
            v-model="form.username"
            type="text"
            required
            placeholder="Enter Username"
            ></b-form-input>
        </b-form-group>
        <b-form-group
            label-cols-sm="3"
            label="Email Address"
            label-align-sm="right"
            label-for="nestedEmail"
            >
            <b-form-input id="nestedEmail" 
                v-model="form.email" 
                type="email"
                required
                placeholder="Enter Email Address"
            ></b-form-input>
        </b-form-group>
        <b-form-group 
            label-cols-sm="3"
            label="Password"
            label-align-sm="right"
            label-for="nestedPassword"
            >
            <b-form-input
            id="nestedPassword"
            v-model="form.password"
            :state="validation"
            type="password"
            required
            placeholder="Enter Password"></b-form-input>
            <b-form-valid-feedback :state="validation"></b-form-valid-feedback>
            <b-form-invalid-feedback :state="validation">Password must be 8-20 characters long.</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group 
            label-cols-sm="3"
            label="Re-Enter Password"
            label-align-sm="right"
            label-for="nestedReEnterPassword"
            >
            <b-form-input
            id="nestedReEnterPassword"
            v-model="form.reenterpassword"
            :state="validationmatch"
            type="password"
            required
            placeholder="Re-Enter Password"></b-form-input>
            <b-form-valid-feedback :state="validationmatch"></b-form-valid-feedback>
            <b-form-invalid-feedback :state="validationmatch">The passwords you entered do not match.</b-form-invalid-feedback>
        </b-form-group>
        <div class="mx-auto mt-4">
            <b-button :disabled="!FormComplete" type="submit" @click="handleSubmit" variant="primary">Submit</b-button>
        </div>
        </b-form-group>
    </b-card>
  </div>
  <div v-else>
      <br/>
      <span><h4>Congratulations {{this.form.firstName}}, your user profile has been created. A verification email has been sent to {{this.form.email}}. Follow the instructions in the email to login for the first time.</h4></span>
      <br/>
      <b-button type="submit" @click="loginRedirect" variant="primary">Go to Login Page</b-button>
  </div>
</template>
<style scoped>
    #profileCard {
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
</style>
<script>
// @ is an alias to /src
import APIService from '@/APIService';
const apiService = new APIService();

export default {
  name: 'userprofile',
  components: {
  },
  data() {
    return {
        form: {
            firstName: '',
            lastName: '',
            username: '',
            email: '',
            password: '',
            reenterpassword: ''
        },
        errorMessage: '',
        passwordsmatch: false,
        isRequestComplete: false,
    }
  },
  computed: {
      showError() {
          return this.errorMessage.length > 0;
      },
      validation(){
          if (this.form.password.length == 0){
              return null;
          } else {
              return this.form.password.length > 8 && this.form.password.length < 20;
          }

      },
      validationmatch(){
          if (this.form.reenterpassword.length == 0){
              return null
          } else {
              return this.form.password == this.form.reenterpassword;
          }
      },
      FormComplete(){
          return this.form.firstName.length > 0 && this.form.lastName.length > 0 && this.form.username.length > 0 && this.form.email.length > 0 && this.form.password.length > 8 && this.form.password.length < 20 && this.form.password == this.form.reenterpassword;
      }
    },

  methods: {
    handleSubmit(e) {
        e.preventDefault();
        if (this.FormComplete) {
        apiService.postCreateUserProfile(this.form.username, this.form.email, this.form.firstName, this.form.lastName, this.form.password, this.$route.params.inviteCode)
                .then((response) => {
                    if (response.status == 200) {
                      //console.log(response)
                      this.isRequestComplete = true;
                    } else {
                        throw (response.data)
                    }
                })
                .catch( (error) => {
                    //console.log(error)
                    let msg;
                    switch (error.message) {
                        case 'Username already being used.':
                            msg = 'Username is already being used.';
                            this.form.username = ''
                            break;
                        case 'Request failed with status code 402':
                            msg = 'An error occured while attempting to send the email';
                            this.form.email =''
                            break;
                        default:
                            msg = 'An unknown error occurred logging in. Please Try again';
                            break;
                    }
                    this.errorMessage = msg; 
                });
        }


    },
    loginRedirect(){
        this.$router.push('/login')
    }
  }
}
</script>
