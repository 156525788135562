<template>
    <div>
        <div class="row flex-column mt-3 w-100">
            <h5>First Four</h5>
            <div class="row flex-column flex-md-row round0" >
                <BracketSlot  v-for="index in bracketStructure.firstFour" :key="index" :gameData="getGameData(index)"  :isInner="false" border="MIDDLE"/>
            </div>
        </div>
        <div class="row flex-column flex-md-row">
            <div class="col-sm d-flex flex-column px-0">
                <h5>Round 1</h5>
                <BracketSlot  v-for="index in bracketStructure.r1Left" :key="index" :gameData="getGameData(index)"  :isInner="false" border="RIGHT" />
            </div>
            <div class="col-sm d-flex flex-column px-0">
                <h5>Round 2</h5>
                <BracketSlot  v-for="index in bracketStructure.r2Left" :key="index" :gameData="getGameData(index)"  :isInner="true"  border="RIGHT" />
            </div>
            <div class="col-sm d-flex flex-column px-0">
                <h5>Round 3</h5>
                <BracketSlot v-for="index in bracketStructure.r3Left" :key="index" :gameData="getGameData(index)"  :isInner="true" border="RIGHT" />
            </div>
            <div class="col-sm d-flex flex-column px-0">
                <h5>Round 4</h5>
                <BracketSlot v-for="index in bracketStructure.r4Left" :key="index" :gameData="getGameData(index)"  :isInner="true" border="RIGHT" />
            </div>
            <div class="col-sm d-flex flex-column px-0">
                <h5>Round 5</h5>
                <BracketSlot v-for="index in bracketStructure.r5Left" :key="index" :gameData="getGameData(index)"  :isInner="true" border="RIGHT" />
            </div>

            <div class="col-sm d-flex flex-column px-0">
                <h5>Finals</h5>
                <div style="height:25%"/>
                <BracketSlot v-for="index in bracketStructure.r6" :key="index" :gameData="getGameData(index)"  :isInner="true" border="MIDDLE" />
                <div style="height:25%"/>
            </div>

            <div class="col-sm d-flex flex-column px-0">
                <h5>Round 5</h5>
                <BracketSlot v-for="index in bracketStructure.r5Right" :key="index" :gameData="getGameData(index)"  :isInner="true" border="LEFT" />
            </div>
            <div class="col-sm d-flex flex-column px-0">
                <h5>Round 4</h5>
                <BracketSlot v-for="index in bracketStructure.r4Right" :key="index" :gameData="getGameData(index)"  :isInner="true" border="LEFT" />
            </div>
            <div class="col-sm d-flex flex-column px-0">
                <h5>Round 3</h5>
                <BracketSlot v-for="index in bracketStructure.r3Right" :key="index" :gameData="getGameData(index)"  :isInner="true" border="LEFT" />
            </div>
            <div class="col-sm d-flex flex-column px-0">
                <h5>Round 2</h5>
                <BracketSlot  v-for="index in bracketStructure.r2Right" :key="index" :gameData="getGameData(index)"  :isInner="true"  border="LEFT" />
            </div>
            <div class="col-sm d-flex flex-column px-0">
                <h5>Round 1</h5>
                <BracketSlot  v-for="index in bracketStructure.r1Right" :key="index" :gameData="getGameData(index)"  :isInner="false" border="LEFT" />
            </div>
        </div>
    </div>
</template>
<style scoped>
    .round0 {
        margin:1em;
        width: 100%;
        justify-content: space-evenly;
    }

    .round0 > * {
        margin:.0 1em 1em 1em;
    }
</style>


<script>
// @ is an alias to /src
import BracketSlot from "@/components/BracketSlot.vue";

export default {
  name: "BracketDesktop",
  components: {
     BracketSlot
  },
  props: {
    bracketStructure: Object,
    gameList: Array,
    teamList: Array
  },
  data() {
    return {
    }
  },
  methods: {
    getGameData(index) {
        let game = this.gameList.find(g => g.GameNbr === index);
      return {
          game,
          team1: this.teamList.find(t => t.TeamID === game.Team1ID),
          team2: this.teamList.find(t => t.TeamID === game.Team2ID)
      };
    }
  },
  created: function () {
    
  }
};
</script>