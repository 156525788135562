<template>
    <div id="loginCard" class="text-center">
        <b-alert :show="showError" variant="danger" class="mt-4">{{ errorMessage }}</b-alert>
    <b-card
        title="Sign In"
        tag="article"
        style="max-width: 20rem;"
        class="my-3 text-center"
    >
      <form>
        <label for="loginEmail" class="">Your Username</label>
        <b-form-input id="loginEmail" v-model="userid" placeholder="Enter your Username"></b-form-input>
        <a class="forgotlinks" href="#/forgotpassword/username"> Forgot username? </a>
        <br/>
        <br/>
        <label for="loginPassword" class="">Your password</label>
        <b-form-input id="loginPassword" v-model="password" placeholder="Enter your password" type="password"></b-form-input>
        <a class="forgotlinks" href="#/forgotpassword/password"> Forgot password? </a>
        <div class="mx-auto mt-4">
            <b-button  type="submit" @click="handleSubmit" variant="primary">Login</b-button>
        </div>
        <br/>
       <!-- <a class="forgotlinks" href="#/userprofile"> Create an account. </a> -->
      </form>
    </b-card>
  </div>
</template>
<style scoped>
    #loginCard {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
</style>
<script>
// @ is an alias to /src
import APIService from '@/APIService';
import axios from 'axios';
import { EventBus } from '@/EventBus.js';
const apiService = new APIService();

export default {
  name: 'login',
  components: {
  },
  data() {
    return {
        userid: '',
        password: '',
        errorMessage: ''
    }
  },
  computed: {
      showError() {
          return this.errorMessage.length > 0;
      }
  },
  methods: {
    handleSubmit(e) {
        e.preventDefault();
        if (this.password.length > 0) {
            apiService.postLoginRequest(this.userid, this.password)
                .then((response) => {
                    if(response.status == 200) {
                    console.log(response)
                    let data = response.data;
                    let is_admin = data.user.is_admin
                    this.$root.$data.user = data.user
                    localStorage.setItem('user',JSON.stringify(data.user))
                    localStorage.setItem('userid',data.user.UserID)
                    localStorage.setItem('jwt',data.token)
                    axios.defaults.headers.common= {Authorization: `Bearer ${localStorage.getItem('jwt')}`};

                    if (localStorage.getItem('jwt') != null){
                        EventBus.$emit('logged-in')
                        if(this.$route.params.nextUrl != null){
                            this.$router.push(this.$route.params.nextUrl)
                        }
                        else {
                            if(is_admin== 1){
                                this.$router.push('admin')
                            }
                            else {
                                this.$router.push('/')
                            }
                        }
                    }  
                    } else {
                        throw (response.data)
                    }

                })
                .catch( (error) => {
                    console.log(error)
                    let msg;
                    switch (error.message) {
                        case 'No user found.':
                            msg = 'Authentication Error. Incorrect username or password.';
                            break;
                        case 'Invalid Credentials':
                            msg = 'Authentication Error. Incorrect username or password.';
                            break;
                        default:
                            msg = 'an unkown error occurred logging in. Please Try again';
                            break;
                    }
                    this.errorMessage = msg; 
                    this.password = ''
                });
        }
    }
  },
  mounted() {
  }
}
</script>
<style>
.forgotlinks {
    font-size: smaller;
}
</style>
