import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Admin from './views/Admin.vue'
import Bracket from './views/Bracket.vue'
import Help from './views/Help.vue'
import Login from './views/Login.vue'
import ForgotPassword from './views/ForgotPassword.vue'
import CreateProfile from './views/CreateProfile.vue'
import ResetPassword from './views/ResetPassword.vue'
import Dashboard from './views/Dashboard.vue'
import Results from './components/Results.vue'
import EmailVerify from './views/EmailVerify.vue'
//import { CardPlugin } from 'bootstrap-vue'

Vue.use(Router)

// meta could be: requiresAuth, guest, or requiresAdmin
const router = new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/bracket/:bracketid',
      name: 'bracket',
      component: Bracket,
      meta: { 
        requiresAuth: true
      }
    },
    {
      path: '/admin/:bracketid',
      name: 'admin',
      component: Admin,
      meta: { 
        requiresAuth: true/*,
        requiresAdmin: true*/
      }
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: { 
            guest: true
        }
    },
    {
      path: '/help',
      name: 'help',
      component: Help,
    },
    {
      path: '/api/auth/emailverify/:emailVerifyToken',
      name: 'emailverify',
      component: EmailVerify,
      meta: {
        guest: true
      }
    },
    {
      path: '/forgotpassword/:switch',
      name: 'forgotpassword',
      component: ForgotPassword,
      meta: { 
        guest: true
    }
    },
    {
      path: '/createprofile/:inviteCode',
      name: 'createprofile',
      component: CreateProfile,
      meta: { 
        guest: true
    }
    },
    {
      path: '/resetpassword/:resetPasswordToken',
      name: 'resetpassword',
      component: ResetPassword,
      meta: { 
        guest: true
    }
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: Dashboard,
      meta: { 
      },
      children: [
        {
          path: 'dashboard/:id',
          component: Results
        },
        {
          path: 'bracket/:id',
          component: Bracket
        },
        {
          path: 'admin/:id',
          component: Admin
        },
      ]
    }
  ]
})

router.beforeEach(function(to, from, next) {

  if(to.matched.some(record => record.meta.requiresAuth)) {
      if (localStorage.getItem('jwt') == null) {
          next({
              path: 'login',
              params: { nextUrl: to.fullPath }
          })
      } else {
          //let user = router.app.$root.$data.user;
          let user = JSON.parse(localStorage.getItem('user'));
          if(to.matched.some(record => record.meta.requiresAdmin)) {
              if(user.isAdmin == 1){
                  next()
              }
              else{
                  next({ name: 'bracket', params: { bracketid: '1'}})
              }
          }else {
              next()
          }
      }
      //May remove this entire else-if section.
  } else if(to.matched.some(record => record.meta.guest)) {
      if(localStorage.getItem('jwt') == null){
          next()
      }
      else{
          next({ name: 'bracket', params: { bracketid: '1'}})
      }
  }else {
      next() 
  }
})

export default router