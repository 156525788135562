<template>
  <div class="row flex">
    <b-tabs content-class="mt-3">
      <b-tab title="First Four" active>
        <div class="row flex">
          <div class="col d-flex flex-column px-2">
            <BracketSlot  v-for="index in bracketStructure.firstFour" :key="index" :gameData="getGameData(index)"  :isInner="false" border="MIDDLE"/>
          </div>
        </div>
      </b-tab>
      <b-tab title="Round 1" active>
        <div class="row flex">
          <div class="col d-flex flex-column px-2">
            <BracketSlot v-for="index in bracketStructure.r1Left" :key="index" :gameData="getGameData(index)"  :isInner="false" border="RIGHT"  />
          </div>
          <div class="col d-flex flex-column px-2">
            <BracketSlot v-for="index in bracketStructure.r1Right" :key="index" :gameData="getGameData(index)"  :isInner="false" border="LEFT"  />
          </div>
        </div>
      </b-tab>
      <b-tab title="Round 2">
        <div class="row flex">
          <div class="col d-flex flex-column px-2">
            <BracketSlot v-for="index in bracketStructure.r2Left" :key="index" :gameData="getGameData(index)"  :isInner="false" border="RIGHT"  />
          </div>
          <div class="col d-flex flex-column px-2">
            <BracketSlot v-for="index in bracketStructure.r2Right" :key="index" :gameData="getGameData(index)"  :isInner="false" border="LEFT"  />
          </div>
        </div>
      </b-tab>
      <b-tab title="Round 3">
        <div class="row flex">
          <div class="col d-flex flex-column px-2">
            <BracketSlot v-for="index in bracketStructure.r3Left" :key="index" :gameData="getGameData(index)"  :isInner="false" border="RIGHT"  />
          </div>
          <div class="col d-flex flex-column px-2">
            <BracketSlot v-for="index in bracketStructure.r3Right" :key="index" :gameData="getGameData(index)"  :isInner="false" border="LEFT"  />
          </div>
        </div>
      </b-tab>
      <b-tab title="Round 4">
        <div class="row flex">
          <div class="col d-flex flex-column px-2">
            <BracketSlot v-for="index in bracketStructure.r4Left" :key="index" :gameData="getGameData(index)"  :isInner="false" border="RIGHT"  />
          </div>

          <div class="col d-flex flex-column px-2">
            <BracketSlot v-for="index in bracketStructure.r4Right" :key="index" :gameData="getGameData(index)"  :isInner="false" border="LEFT"  />
          </div>
        </div>
      </b-tab>
      <b-tab title="Round 5">
        <div class="row flex">
          <div class="col d-flex flex-column px-2">
            <BracketSlot v-for="index in bracketStructure.r5Left" :key="index" :gameData="getGameData(index)"  :isInner="false" border="RIGHT"  />
          </div>
          <div class="col d-flex flex-column px-2">
            <BracketSlot v-for="index in bracketStructure.r5Right" :key="index" :gameData="getGameData(index)"  :isInner="false" border="LEFT"  />
          </div>
        </div>
      </b-tab>
      <b-tab title="Finals">
        <div class="col d-flex flex-column px-2">
          <div style="height:25%" />
          <BracketSlot v-for="index in bracketStructure.r6" :key="index" :gameData="getGameData(index)"  :isInner="false" border="MIDDLE" />
          <div style="height:25%" />
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>



<script>
// @ is an alias to /src
import BracketSlot from "@/components/BracketSlot.vue";

export default {
  name: "BracketDesktop",
  components: {
    BracketSlot
  },
  props: {
    bracketStructure: Object,
    gameList: Array,
    teamList: Array
  },
  data() {
    return {};
  },
  methods: {
    getGameData(index) {
        let game = this.gameList.find(g => g.GameNbr === index);
      return {
          game,
          team1: this.teamList.find(t => t.TeamID === game.Team1ID),
          team2: this.teamList.find(t => t.TeamID === game.Team2ID)
      };
    }
  },
  created: function() {}
};
</script>