<template>
    <BracketGroup :bracketid="bracketid" ref="bracketGroup" />
</template>


<style>

</style>

<script>
import BracketGroup from "@/components/BracketGroup.vue";
import APIService from '@/APIService';
import { EventBus } from '@/EventBus.js';
const api = new APIService();

export default {
  name: "admin",
  components: {
     BracketGroup
  },
  props: {
  },
  data() {
    return {
      bracketid: parseInt(this.$route.params.id, 10)
    }
  },
  watch: {
    '$route.params.id': function (id) {
      localStorage.setItem('bracketid', id);
      this.bracketid = parseInt(id, 10);
    }
  },
  methods: {
    async onTeamSelected(e) {
      try {
        let response = await api.updateMasterBracket(e.TeamID, e.GameNbr, localStorage.getItem('bracketid'));

        if (response.status !== 200) throw new Error('Bad response from webserver.');

        this.$refs.bracketGroup.refreshTeams();
      } catch (ex) {
        EventBus.$emit('show-alert', `An error occured while attempting to save your last pick. Please close this box and try again. If issue persists contact webmaster. Message: ${ex.message}`);
      }
    }
  },
  created: function () {
    localStorage.setItem('bracketid', this.$route.params.id);
    this.bracketid = parseInt(this.$route.params.id, 10);
    EventBus.$on('team-selected', this.onTeamSelected);
    
  },
  destroyed() {
    EventBus.$off('team-selected', this.onTeamSelected);
  }
};
</script>

