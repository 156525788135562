<template>
<div>
    TEST
</div>
</template>
<script>
// @ is an alias to /src
import APIService from '@/APIService';
const apiService = new APIService();

export default {
  name: 'emailverify',
  components: {
  },
  data() {
    return {
        isRequestComplete: false,
    }
  },
  created: async function () {
      await apiService.PostEmailVerify(this.$route.params.emailVerifyToken)
        this.$router.push('/login')

      //let results = await apiService.postCreateUserProfile(this.form.username, this.form.email, this.form.firstName, this.form.lastName, this.form.password, this.$route.params.inviteCode)
      //To-Do will need to remove the next lines.
    } 
}
</script>
