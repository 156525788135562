<template>
  <span v-on:click="selectTeam()" :class="teamStyle" style="display:flex">
    <span class="team-name"> {{team.Name}}</span>
    <template :v-if="team.Rank>0" >
      <span class="team-rank"> ({{team.Rank}}) </span>
    </template>
  </span>
</template>

<script>
import { EventBus } from '@/EventBus';

export default {
  name: 'TeamSlot',
  props: {
    team: Object,
    game: Object
  },
  data() {
    return {
      isAdminView: this.testPath(this.$route.path),
     }
  },
  computed: {
      isSelected() {
        return this.game.SelectedID === this.team.TeamID;
      },
      isWinner() {
        return this.game.WinnerID === this.team.TeamID;
      },
      hasWinner() {
        return this.game.WinnerID !== 0 && this.game.WinnerID !== null;
      },
      teamStyle() {
        if (this.isAdminView) 
          return {
            'team-selected': this.isAdminView && this.hasWinner && this.isWinner
          };
        else
          return {
            'winner-selected': this.hasWinner && this.isSelected && this.isWinner,
            'loser-selected': this.hasWinner && this.isSelected && !this.isWinner,
            'team-selected': this.isSelected
          };
      },
  },
  methods: {
    selectTeam() {
      EventBus.$emit('team-selected', {GameNbr: this.game.GameNbr, TeamID: this.team.TeamID, Game: this.game});
    },
    testPath(text) {
      return /\/dashboard\/admin\//g.test(text)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
[data-isSelectable=true] span:hover, span:active {
  background-color:var(--primary);
  color: var(--white);
  cursor: pointer;
}
.team-selected {
  border-color: var(--dark) !important;
}
.winner-selected {
  background-color: rgba(0, 128, 0, .3);
  font-weight:bold;
}
.loser-selected {
  background-color:rgba(255, 0, 0, .1);
  font-style:italic;
}
.border-left span > .team-name {
  order: -1;
}
.border-left span > .team-rank {
  order: 1;
  margin-left: .1em;
}
.border-right span > .team-name {
  order: 1;
  margin-left: .1em;
}
.border-right span > .team-rank {
  order: -1;
}
</style>
