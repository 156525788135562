<template>
  <div id="app" class="">
    <b-navbar toggleable="sm" type="dark" variant="primary" class="px-1">
      <b-navbar-brand href="#"><img src="../public/favicon.png">U Pick Em Challenge</b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav id="nav" >
          <b-nav-item to="/" >Home</b-nav-item>
          <b-nav-item to="/dashboard" >Dashboard</b-nav-item>
          <b-nav-item to="/help">Help</b-nav-item>
        </b-navbar-nav>

        <b-navbar-nav class="ml-auto" right >
          <span class="navbar-text mr-4" v-if="isLoggedIn">{{userFirstName}}</span>
          <router-link :to="`/createprofile/-1`" v-if=!isLoggedIn><span class="navbar-text mr-4">Create Profile</span></router-link>
           <b-button variant="outline-light" class="py-1" v-on:click="handleLogin">
             {{(isLoggedIn ? 'Log Out': 'Log In')}}
            </b-button>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
      <b-alert v-if="isLegacyEdgeVersion" variant="danger" 
      class="position-fixed fixed-bottom m-0 rounded-0" 
      style="z-index:2000" show><a href="https://www.microsoft.com/en-us/edge" class="alert-link">
      {{ errorMessage }}</a>
    </b-alert>
    <router-view/>
  </div>
</template>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>

<script>
// @ is an alias to /src
import { EventBus } from './EventBus.js';
import axios from 'axios';

export default {
  name: 'app',
  components: {
  },
  computed: {
    userFirstName() {
      return this.isLoggedIn ? 'Logged in as ' + JSON.parse(localStorage.getItem('user')).UserFirstName :''
    }
  },
  data() {
    return {
      isLoggedIn: false,
      isLegacyEdgeVersion: this.checkEdgeVersion(),
      errorMessage: '',
      dismissSecs: 7,
      dismissCountDown: 0
    }
  },
  methods: {
    handleLogin() {
      let user = localStorage.getItem('userid');
      let jwt = localStorage.getItem('jwt');
      if (user === null || jwt === null) {
         this.$router.push('/login');
      } else {
        localStorage.removeItem('userid');
        localStorage.removeItem('jwt');
        axios.defaults.headers.common= {};
        localStorage.removeItem('user');
        EventBus.$emit('logged-out');
        this.$router.push('/');
      }
    },
    updateLoginStatus() {
      this.isLoggedIn = localStorage.getItem('jwt') !== null;
    },
    checkEdgeVersion() {
      var ua = window.navigator.userAgent
      var edge = ua.indexOf('Edge/')
      return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)),10) < 80 ? true:false
    },
    showAlert(msg) {
      this.errorMessage = msg;
    }
  },
  mounted() {
    this.checkEdgeVersion() ? this.showAlert("You are using a legacy version of Microsoft Edge.  You may continue to use the website but for best results please download the new Microsoft Edge by clicking here."): ''
    this.updateLoginStatus();
    EventBus.$on('logged-in', () => {
      this.updateLoginStatus()
    });
    EventBus.$on('logged-out', () => {
      this.updateLoginStatus()
    });
  },
};
</script>