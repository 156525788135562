<template>
   <div v-if="game" class="h-100 my-2" :class="parentStyle" >
    <div class="border-top border-bottom border-dark position-relative" :class="childStyle" :data-isSelectable="isSelectable" >
      <TeamSlot v-if="team1" :team="team1" :game="game" />
      <span v-else>&nbsp;</span>
      <div v-if="isSelectable && !isSelected" :id="`gameAlert${game.GameNbr}`" :class="iconStyle">
        <b-icon icon="alert-triangle" variant="danger" scale="1.5"  class="d-print-none"></b-icon>
    <b-tooltip :target="`gameAlert${game.GameNbr}`" triggers="click hover blur" variant="danger">
      Pick Due By {{ formattedDeadline }}
    </b-tooltip>
      </div>
      <TeamSlot v-if="team2" :team="team2" :game="game" />
      <span v-else>&nbsp;</span>
    </div>  
  </div>
</template>

<script>
import TeamSlot from '@/components/TeamSlot.vue';

export default {
  
  name: 'BracketSlot',
  components: {
    TeamSlot
  },
  props: {
    gameData: Object,
    isInner: Boolean,
    border: String
  },
  data() {
    return {
      //isAdminView: this.$route.name === 'admin',
      isAdminView: this.testPath(this.$route.path),
      isMiddle: this.border === 'MIDDLE',
      isLeft: this.border === 'LEFT',
      isRight: this.border === 'RIGHT'
     }
  },
  computed: {
    game() {
      return this.gameData.game;
    },
    team1() { 
      return this.gameData.team1;
    },
    team2() { 
      return this.gameData.team2;
    },
    parentStyle () {
      return {
        'my-md-0': this.isMiddle || this.isInner,
      };
    },
    childStyle() {
      return {
        'border-left': this.isLeft,
        'rounded-left': this.isLeft,
        'border-right': this.isRight,
        'rounded-right': this.isRight,
        'text-center': this.isMiddle,
        'inner-bracket': this.isInner,
        'outer-bracket': !this.isInner
      };
    },
    iconStyle() {
      return {
        'warning-icon-left': this.isLeft || this.isMiddle,
        'warning-icon-right': this.isRight
      };
    },
    isSelectable() {
      return this.isAdminView || this.game.Deadline > new Date().toISOString() && this.game.Team1ID != 0 && this.game.Team2ID != 0 ? true : false;
    },
    isSelected() {
      return this.game.SelectedID !== 0 && this.game.SelectedID !== null;
    },
    formattedDeadline() {
      var Deadline = new Date(this.game.Deadline);
      return `${Deadline.getMonth()+1}/${Deadline.getDate()}/${Deadline.getFullYear()} ${('0'+Deadline.getHours()).slice(-2)}:${('0'+Deadline.getMinutes()).slice(-2)}`;
    }
  },
  methods: {
    testPath(text) {
      return /\/dashboard\/admin\//g.test(text)
    }
  },
  created() {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.outer-bracket {
  /*margin:5% 0;*/
}
.inner-bracket {
  height: 50%;
}
.border-left > span {
  text-align: right;
  right: 0;
  margin-right: 5%;
}
.border-right > span  {
  text-align: left;
  left: 0;
  margin-left: 5%;
}
[data-isSelectable=true] span:hover, span:active {
  background-color:var(--primary);
  color: var(--white);
  cursor: pointer;
}
.inner-bracket > span,
.outer-bracket > span {
  border-style: solid;
  border-color:transparent;
  white-space: nowrap;
}
.border-left > span:first-child {
  border-width: 2px 3px 3px 2px;
  border-bottom-right-radius: .25rem;
}
.border-left > span:last-child {
  border-width: 3px 3px 2px 2px;
  border-top-right-radius: .25rem;
}
.border-right > span:first-child {
  border-width: 2px 2px 3px 3px;
  border-bottom-left-radius: .25rem;
}
.border-right > span:last-child {
  border-width: 3px 2px 2px 3px;
  border-top-left-radius: .25rem;
}
.text-center > span:first-child {
  border-width: 2px 3px 3px 3px;
}
.text-center > span:last-child {
  border-width: 3px 3px 2px 3px;
}
.inner-bracket > span:last-child {
  position: relative;
  width: 95%;
  bottom: 0;
}
.inner-bracket > span:first-child {
  position: relative;
  width: 95%;
  top: 0;
}

@media (min-width: 768px) { 
  .inner-bracket > span:last-child {
    position: absolute;
    width: 95%;
    bottom: 0;
  }
  .inner-bracket > span:first-child {
    position: absolute;
    width: 95%;
    top: 0;
  }
  .warning-icon-left, .warning-icon-right {
    top: initial;
  }
}
.warning-icon-left, .warning-icon-right {
  display: flex;
  align-items: center;
  position: absolute;
  height: 100%;
  top: 0;
}
.warning-icon-left {
  left: 5%;
}
.warning-icon-right {
  right: 5%;
}
</style>
