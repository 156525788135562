import axios from 'axios';
import { EventBus } from './EventBus';
import { router } from './main';

axios.defaults.baseURL = process.env.VUE_APP_APIENDPOINT || 'https://upickemchallenge.com';

export default class APIService {

    constructor() {
        axios.defaults.headers.common= {Authorization: `Bearer ${localStorage.getItem('jwt')}`};
    }
    
    getUserBracket(userid, bracketid) {
        return axios.get(`/api/bracket/user`, {params: {userid, bracketid}})
                    .then(response => response.data)
                    .catch(this.handleError);
    }
    
    getBracket(bracketid) {
        return axios.get(`/api/bracket/${bracketid}`)
                    .then(response => response.data)
                    .catch(this.handleError);
    }
    
    getTeams(bracketid) {
        return axios.get(`/api/bracket/teams/`, {params: {bracketid}})
                    .then(response => response.data)
                    .catch(this.handleError);
    }
    
    addSelections(bracketid, userid, gameid, selectedid) {
        return axios.post(`/api/bracket/user`, {bracketid, userid, selectedid, gameid})
                    .then(function(response) { return response;})
                    .catch(this.handleError);
    }

    PostEmailVerify(emailVerifyToken) {
        return axios.post(`/api/auth/emailverify`, {emailVerifyToken})
                    .then(function(response) { return response;})
                    .catch(this.handleError);
    }
    

    getGroupAdmins(bracketid) {
        return axios.get(`/api/group/getadmins`,{params: {bracketid}})
                    .then(function(response) { return response;})
                    .catch(this.handleError);
    }
    
    postLoginRequest(userid, password) {
        return axios.post(`/api/auth/loginuser`, { userid, password })
                    .then(function(response) { return response; })
                    .catch(this.handleError);
    }

    postPasswordResetRequest(email, type) {
        return axios.post(`/api/auth/forgotpassword`, { email, type })
                    .then(function(response) { return response; })
                    .catch(this.handleError);
    }

    postUpdatePasswordRequest(resetPasswordToken, password) {
        return axios.post(`/api/auth/updatepasswordviaemail`, { resetPasswordToken, password })
                    .then(function(response) { return response; })
                    .catch(this.handleError);
    }

    postCreateUserProfile(username, email, userfn, userln, password, invitecode) {
        return axios.post(`/api/auth/createuserprofile`, { username, email, userfn, userln, password, invitecode })
                    .then(function(response) { return response; })
                    .catch(this.handleError);
    }

    getResults(bracketid) {
        return axios.get(`/api/bracket/report`,{params: {bracketid}})
                    .then(function(response) { return response.data; })
                    .catch(this.handleError);
    }

    updateMasterBracket(teamid, gameid, bracketid) {
        return axios.patch(`/api/bracket`, {teamid, gameid, bracketid})
                    .then(function(response) { console.log('handling response');return response; })
                    .catch(this.handleError);
    }

    getGroupsbyUser(userid) {
        return axios.get(`/api/group/getgroupsbyuser`, {params: {userid}})
                    .then(function(response) { console.log('handling response');return response; })
                    .catch(this.handleError);
    }

    postNewGroup(groupName, bracketName, adminID) {
        return axios.post(`/api/group/create`, {groupName, bracketName, adminID})
                    .then(function(response) { console.log('handling response');return response; })
                    .catch(this.handleError);
    }

    handleError(err) {
        
        if (err.response.status === 401) {
            localStorage.removeItem('userid');
            localStorage.removeItem('jwt');
            axios.defaults.headers.common= {};
            localStorage.removeItem('user');
            EventBus.$emit('logged-out');
            router.push('/');
            return err.response;
        }
    return err.response;}
}