<template>
    <BracketGroup :bracketid="bracketid"/>
</template>

<style>
</style>

<script>
import BracketGroup from "@/components/BracketGroup.vue";
import APIService from '@/APIService';
import { EventBus } from '@/EventBus';
const api = new APIService();

export default {
  name: "bracket",
  components: {
     BracketGroup
  },
  props: {
  },
  data() {
    return {
      bracketid: parseInt(this.$route.params.id, 10)
    }
  },
  watch: {
    '$route.params.id': function (id) {
      localStorage.setItem('bracketid', id);
      this.bracketid = parseInt(id, 10);
    }
  },
  methods: {
    onTeamSelected(e) {
      let isSelectable = e.Game.Deadline > new Date().toISOString() && e.Game.Team1ID != 0 && e.Game.Team2ID != 0 ? true : false;
      if (isSelectable) {
        api.addSelections(localStorage.getItem('bracketid'), localStorage.getItem('userid'), e.GameNbr, e.TeamID)
          .then((response)=> {

            if (response.status == 200) {
              e.Game.SelectedID = e.TeamID;
            } else {
              EventBus.$emit('show-alert', "An error occured while attempting to save your last pick. Please close this box and try again. If issue persists contact webmaster.");
            }
          })
      } else {
        EventBus.$emit('show-alert', "You cannot select a winner for that game yet or the deadline for the game you've selected has already passed.");
      }     
    }
  },
  created: function () {
    localStorage.setItem('bracketid', this.$route.params.id);
    this.bracketid = parseInt(this.$route.params.id, 10);

    EventBus.$on('team-selected', this.onTeamSelected);
    
  },
  destroyed() {
    EventBus.$off('team-selected', this.onTeamSelected);
  }
};/*
// 4: {…}
// Bottom: 2
// 
// BracketID: 1
// 
// Deadline: "2100-01-01T00:00:00.000Z"
// 
// GameNbr: 1
// 
// Result: null
// 
// RoundNbr: 6
// 
// SelectedID: null
// 
// Team1ID: 0
// 
// Team2ID: 0
// 
// Top: 3
// 
// WinnerID: 0*/
</script>

