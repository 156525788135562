<template>
    <div id="loginCard" class="text-center">
        <b-alert :show="showError" variant="danger" class="mt-4">{{ errorMessage }}</b-alert>
    <b-card
        title="Reset Password"
        tag="article"
        style="max-width: 20rem;"
        class="my-3 text-center"
    >
      <form>
        <label for="loginPassword" class="">Enter Your New Password</label>
        <b-form-input id="loginPassword" type ="password" required v-model="password" placeholder="Enter Password"></b-form-input>
        <br/>
        <div class="mx-auto mt-4">
            <b-button type="submit" @click="handleSubmit" variant="primary">Submit</b-button>
        </div>
      </form>
    </b-card>
  </div>
</template>
<style scoped>
    #loginCard {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
</style>
<script>
// @ is an alias to /src
import APIService from '@/APIService';
//import { EventBus } from '@/EventBus.js';
const apiService = new APIService();

export default {
  name: 'resetpassword',
  components: {
  },
  data() {
    return {
        password: '',
        errorMessage: '',
    }
  },
  computed: {
      showError() {
          return this.errorMessage.length > 0;
      }
  },
  methods: {
    handleSubmit(e) {
        e.preventDefault();
            apiService.postUpdatePasswordRequest(this.$route.params.resetPasswordToken, this.password)
                .then((response) => {
                    console.log(response)
                    this.$router.push('/login')
                })
                .catch( (error) => {
                    console.log(error)
                    let msg;
                    switch (error.message) {
                        case 'Request failed with status code 400':
                            msg = 'An error occured trying to update the password.';
                            break;
                        case 'Request failed with status code 401':
                            msg = 'The password reset token is invalid or expired.';
                            break;
                        default:
                            msg = 'An unkown error occurred while trying to reset the password. Please Try again';
                            break;
                    }
                    this.errorMessage = msg; 
                });

    }
  }
}
</script>
