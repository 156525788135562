<template>
  <div class="home">
    <b-jumbotron header-tag='h5'>
      <template v-slot:header >Welcome to uPickem Challenge!</template>
      <template v-slot:lead>Create your own bracket! Whether it be March Madness, NBA Playoffs, MLB Playoffs, the brackets are customizable and it should be fun for everybody.</template>
      <hr class="my-4">
      <template v-if="!isLoggedIn">
      <p>
        Login using an existing account or create an account and invite friends to join your group.
      </p>
      <router-link :to="`/login`"><b-button class="mr-1" variant="primary">Log In</b-button></router-link>
      <router-link :to="`/createprofile/-1`"><b-button variant="success">Create Account</b-button></router-link>
      </template>
      <template v-if="isLoggedIn">
      <p>
        To view your dashboard click on the button below or the Dashboard link in the navigation bar.
      </p>
      <b-button class="mr-1" variant="primary" href="#/dashboard">My Dashboard</b-button>
      </template>
      </b-jumbotron>
            <b-container fluid>
        <b-row>
          <b-col fluid class="col-md-12"><h4>Make your picks and see your previous results in the same place.</h4>
            <br />
            <img src="../assets/makingselections.gif" alt="Bracket Preview">
          </b-col>
        </b-row>
        </b-container>
      </div>
</template>

<script>
// @ is an alias to /src
import { EventBus } from '../EventBus.js';

export default {
  name: 'home',
  components: {
  },
  computed: {
    userFirstName() {
      return this.isLoggedIn ? JSON.parse(localStorage.getItem('user')).UserFirstName :''
    }
  },
  data() {
    return {
      isLoggedIn: false,
      errorMessage: ''
    }
  },
  methods: {
    updateLoginStatus() {
      this.isLoggedIn = localStorage.getItem('jwt') !== null;
      console.log(this.isLoggedIn)
    },
    showAlert(msg) {
      this.errorMessage = msg;
    }
  },
  mounted() {
    this.updateLoginStatus();
      EventBus.$on('logged-in', () => {
        this.updateLoginStatus()
      });
      EventBus.$on('logged-out', () => {
        this.updateLoginStatus()
      });
  },
};
</script>
<style>
img {
  max-width: 100%;
  height: auto;
}
</style>
